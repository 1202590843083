export const tableItems = [
  {
    key: 'username',
    label: '用户名'
  }, {
    key: 'realName',
    label: '姓名'
  }, {
    key: 'mobile',
    label: '手机号',
  }, {
    key: 'email',
    label: '邮箱'
  }, {
    key: 'tenantNameList',
    label: '关联仓库',
    slot: true
  }, {
    key: 'ownerNameList',
    label: '关联组织',
    slot: true
  }, {
    key: 'status',
    label: '状态',
    width: 100,
    slot: true
  }, {
    key: 'createDate',
    label: '注册时间'
  }, {
    type: 'actions',
    label: '操作',
    width: 150,
    fixed: 'right'
  }
]

export const pwdItems = [
  {
    key: 'password',
    label: '密码',
    component: 'password',
    rules: [
      { required: true, message: '请填写密码', trigger: 'blur' },
      { pattern: /^\S{6,32}$/, message: '请正确填写密码', trigger: 'blur' }
    ]
  }, {
    key: 'rePwd',
    label: '确认密码',
    component: 'password',
    rules: [
      { required: true, message: '请填写确认密码', trigger: 'blur' },
      { pattern: /^\S{6,32}$/, message: '请正确填写确认密码', trigger: 'blur' }
    ]
  }
]