<template>
<div>
  <mts-table
    v-model="pageItems" :pager="{ 'current-page': current, 'page-size':size, total }"
    :loading.sync="dataListLoading" :config="tableConfig"
    @pager:size-change="sizeChanged"
    @pager:current-change="pageChanged">
    <template #tableHeader>
      <el-form class="searchForm" inline>
        <el-form-item>
          <el-input v-model="searchForm.keyword" placeholder="请输入搜索关键词" clearable size="small"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="searchDataList()" size="small" type="primary">搜索</el-button>
          <el-button size="small" @click="resetSearch">重置</el-button>
        </el-form-item>
      </el-form>
    </template>
    <template #tenantNameList="{ row }">
      <div>{{row.tenantNameList.join('、')}}</div>
    </template>
    <template #ownerNameList="{ row }">
      <div>{{row.ownerNameList.join('、')}}</div>
    </template>
    <template #status="{ row }">
      <el-switch :value="row.status" :active-value="1" :inactive-value="0" @change="(status) => updateItemStatus(row, { status })"></el-switch>
    </template>
    <template #tableActions="{ row }">
      <el-button type="text" @click.stop="showPwdWin(row)">密码重置</el-button>
    </template>
  </mts-table>
  <el-dialog
    title="密码重置"
    :visible="winVisible"
    @close="winVisible = false"
    width="300"
  >
    <mts-form v-model="form" :config="formConfig" ref="dialogForm"></mts-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="winVisible = false">取消</el-button>
      <el-button type="primary" @click="submitHandle()">确认</el-button>
    </span>
  </el-dialog>
</div>
</template>
<script>
import { tableItems, pwdItems } from './erpUser.config'
import { mapState, mapActions } from 'vuex'
import mtsTable from '@/components/mts/table'
import mtsForm from '@/components/mts/form'
import _ from 'lodash'


export default {
  data () {
    return {
      tableConfig: {
        tableProps: {
          border: true
        },
        items: tableItems
      },
      searchForm: {
        keyword: ''
      },
      dataListLoading: false,
      form: {},
      winVisible: false
    }
  },
  components: {
    mtsTable, mtsForm
  },
  activated () {
    this.getDataList()
  },
  computed: {
    ...mapState('erpUser', ['current', 'size', 'total', 'pageItems']),
    formConfig () {
      return {
        items: pwdItems,
        props: {
          labelWidth: '120px'
        }
      }
    }
  },
  methods: {
    ...mapActions('erpUser', [ 'page', 'updateStatus', 'resetPassword' ]),
    sizeChanged (size) {
      this.getDataList(this.current, size)
    },
    pageChanged (page) {
      this.getDataList(page)
    },
    searchDataList () {
      this.getDataList(1)
    },
    resetSearch () {
      let form = this.$options.data().searchForm
      this.$set(this, 'searchForm', form)
      this.$nextTick(() => {
        this.getDataList(1)
      })
    },
    getDataList (p, s) {
      if (!p) p = this.current
      if (!s) s = this.size
      this.dataListLoading = true
      let params = {
        page: p,
        limit: s,
        ...this.searchForm
      }
      return this.page(params).then(() => {
        this.dataListLoading = false
      }).catch((e) => {
        this.dataListLoading = false
        this.$message({
          message: e.message,
          type: 'warning',
          duration: 5000
        })
      })
    },
    updateItemStatus (row, data) {
      this.$confirm('确认修改此项的状态？', '修改账号状态', {
        confirmButtonText: '提交',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let form = _.cloneDeep(data)
        form.id = row.id
        return this.updateStatus(form).then(() => {
          this.getDataList()
          this.$message.success('修改账号状态成功')
        }).catch((e) => {
          console.error(e)
          this.$message.error(e.message)
        })
      })
    },
    showDetailWin (row) {
      console.log(row)
    },
    showPwdWin (row) {
      let form = {}
      if (row && row.id) {
        form.id = row.id
      } else return
      this.$set(this, 'form', form)
      this.winVisible = true
      // console.log(row)
    },
    submitHandle () {
      let form = _.cloneDeep(this.form)
      if (form.password !== form.rePwd) {
        this.$alert('两次输入的密码不一致', '密码重置', { type: 'error' })
        return
      }
      delete form.rePwd
      this.$refs.dialogForm.validate().then(() => this.resetPassword(form)).then(() => {
        this.$message.success('密码重置成功')
        this.getDataList(1)
        this.winVisible = false
      }).catch((e) => {
        this.$alert(e.message, '密码重置', { type: 'error' })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.searchForm {
  margin-bottom: -18px;
}
</style>
